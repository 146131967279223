<template>
    <div id="app">
        <transition :name="topTransitionName">
            <router-view class="top-router-view" name="top" mode="out-in"></router-view>
        </transition>
        <navbar></navbar>
        <transition :name="bottomTransitionName">
            <router-view class="bottom-router-view" name="bottom" mode="out-in"></router-view>
        </transition>
        <page-footer></page-footer>
    </div>
</template>

<script>
    import navbar from "./components/navbar.vue";
    import PageFooter from "@/components/footer";

    export default {
        name: 'app',
        components: {
            PageFooter,
            navbar,
        },
        data: function () {
            return {
                topTransitionName: "",
                bottomTransitionName: ""
            }
        }
        ,
        watch: {
            '$route'(to, from) {
                if (to.name === "imprint") {
                    this.bottomTransitionName = "pop-up";
                    // eslint-disable-next-line no-console
                    console.log("opoup");
                    return;
                }

                if (from.name === "imprint") {
                    this.bottomTransitionName = "pop-down";
                    return;
                }

                if (to.name === 0) {
                    this.topTransitionName = "slide-in-from-top";
                    this.bottomTransitionName = "slide-out-to-bottom";
                    return;
                }

                if (from.name === 0) {
                    this.topTransitionName = 'slide-out-to-top';
                    this.bottomTransitionName = 'slide-in-from-bottom';
                }

                if (from.name < to.name) {
                    this.bottomTransitionName = 'slide-left';
                    return;
                }

                if (to.name < from.name) {
                    this.bottomTransitionName = 'slide-right';

                }
            }
        }
    }
</script>

<style lang="scss">
    @import "assets/master";
    @import "assets/transitions";

    body {
        //margin: 2vw;
    }

    .pop-up-enter {
        opacity: 0.01;
        transform: scale(5);
    }

    .pop-up-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: all 3000ms;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    a {
      text-decoration: none;
    }

    @media screen and (min-width: $XL_MIN) {
        #app {
            max-width: $XL_MIN;
            //border: solid pink 2px;
            margin: 0 auto;
            text-align: center;
        }
    }

</style>
