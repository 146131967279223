<template>
    <nav class="navbar">
        <router-link to="/">home</router-link>
<!--        <router-link to="/life">LIFE</router-link>-->
        <router-link to="/study">STUDY</router-link>
        <router-link to="/work">WORK</router-link>
        <router-link to="/skills">SKILLS</router-link>
        <router-link to="/blog">blog</router-link>
    </nav>
</template>

<script>
    export default {
        name: "navbar"
    }
</script>

<style lang="scss" scoped>
    @import "../assets/master";

    nav{
        display: flex;
        justify-content: space-around;
        font-size: $SUBTITLE_SIZE;
        padding: 30px 0;
    }

    @media screen and (max-width: $S_MAX) {
        nav {
            font-size: $SUBTITLE_SIZE_S;
        }
    }

    @media screen and (min-width: $S_MIN) and (max-width: $M_MAX){
        nav {
            font-size: $SUBTITLE_SIZE_S;
        }
    }

    @media screen and (min-width: $XL_MIN) {
        nav{
            margin: 0 30px;
        }

    }

</style>