<template>
  <div class="juri-blog">
      <h2>Blog</h2>
      <article>
      <p>Hier werde ich zunächst auf deutsch Texte zu einigen Themen verfassen. Dieser Blog befindet sich im Aufbau.</p>
      </article>
      <article>
      <h3>Kommentare zu "Alternativen Medien"</h3>
      <p>Ein Verwandter schickt mir hin und wieder Artikel aus Blogs und sonstigen "alternativen Medien" mit der Bitte um Einordnung. Meine Kommentare werde ich auch hier hochladen. Hinweise und Kommentare nehme ich gerne unter <a href="mailto:juriw.website@mailbox.org">juriw.website@mailbox.org</a> an. Beleidigungen und Drohungen zeige ich an.</p>
      <c-v-block>
        <template v-slot:block-date>10. Dezember 2021</template>
        <template v-slot:block-title>Kommentar zu Paul Schreyers "Faktencheck: Sind die mRNA-Injektionen Impfungen oder Gentherapie?"</template>
          <p>Dieser Artikel wurde am 3. Dezember 2021 im "Multipolar Magazin" veröffentlicht.
          </p><p>In dem Artikel wir vorrangig die Frage untersucht, ob mRNA-Impfstoffe eine Gentherapie oder tatsächlich Impfstoffe sind. Dabei werden offizielle Dokumente, ein Bayer-Manager, und zwei weitere Menschen zitiert.
          </p><p>Bevor ich auf das Schriftstück zurückkomme, will ich meinen Wissensstand über Gentherapien und mRNA-Impfstoffe wiedergeben. Ich finde es hierbei wichtig, das „zentrale Dogma der Molekularbiologie“ zu betrachten. Abgewandelt bedeutet es, dass aus DNA RNA wird und aus RNA Proteine. Das Übertragen von DNA zu RNA heißt „Transkription“ und passiert beim Menschen im Zellkern. mRNA ist dabei eine Art von RNA. Das Übersetzen von RNA zu Protein heißt „Translation“ und passiert an den Ribosomen in der Zelle, außerhalb des Zellkerns. 
            Manche Organismen und zum Beispiel Retroviren können auch die sogenannte reverse Transkription, bei der aus RNA wieder DNA wird. Normale menschliche Zellen können das nicht.
            </p><p>Gentherapie bezeichnet gemeinhin das Verändern („Korrigieren“) der DNA im Zellkern. Mit dieser modernen Therapieform können manche genetische Krankheiten geheilt werden.  Deswegen sehe ich mRNA-Impfstoffe nicht als Gentherapie in diesem Sinn. Es wird kein Erbgut verändert, die mRNA kommt nicht mal in den Zellkern. Stattdessen besteht der Impfstoff aus mRNA-Stückchen, die in unserm Fall die Spike-Proteine (spezielle Proteine an der Hülle des Virus) von SARS-CoV-2 kodieren. Diese docken dann an die Ribosomen in der Zelle an, die Zelle produziert die Spike-Proteine. Diese gelangen ins Blut und provozieren da die Immunantwort, wie jeder andere Impfstoff auch. Die mRNA wird in der Zelle schnell abgebaut. 
          </p><p>Letztendlich ist es egal wie man es nennt, finde ich. Die Essenz bleibt: Der Impfstoff ist sicher, Langzeitwirkungen sind nicht zu erwarten (Also ich kann mir als Laie keine Möglichkeit vorstellen, da der Impfstoff nach 2 Wochen nicht mehr im Körper ist. Ärzte kenne auch keinen Mechanismus wie Langzeitwirkungen entstehen sollten.) Vor allem findet keine Veränderung des Erbguts statt! Verglichen mit den bewiesenen Langzeitfolgen von COVID wie ein deutlich höheres Risiko für Herzmuskelentzündungen oder für ME/CFS, anhaltender Geschmacksverlust (Oder alles schmeckt nach Abwasser, gibt’s auch in Einzelfällen), ist die Impfung viel sicherer und lohnt sich auf jeden Fall. Hier sei auch erwähnt, <a href="https://de.wikipedia.org/wiki/SARS-CoV-2#Replikationszyklus">dass SARS-CoV-2 sich selber über die gleiche mRNA-Mechanik vermehrt</a>.
          </p><p>Zurück zum Text: Ich finde es gut, dass man untersucht, ob einem da nicht irgendwas anderes untergejubelt wird, als behauptet. Sachen, die mit Genen zu tun haben, sind vielen Menschen erstmal suspekt, wahrscheinlich auch, weil große Agrarkonzerne das Vertrauen in der Vergangenheit zerstört haben. Die Zitate im Text stimmen. Kann man so überprüfen, sie sind verlinkt.
          </p><p>Das erste Zitat ist von Stefan Oelrich, Vorstand und „Leiter der Division Pharmaceuticals“, seine Ausbildung ist ein Bachelor in Wirtschaft. Er wird richtig zitiert mit „Die mRNA-Impfungen sind ein Beispiel für Zell- und Gentherapie. Hätten wir vor zwei Jahren eine öffentliche Umfrage gemacht und gefragt, wer bereit dazu ist, eine Gen- oder Zelltherapie in Anspruch zu nehmen und sich in den Körper injizieren zu lassen, dann hätten das wahrscheinlich 95 Prozent der Menschen abgelehnt. Diese Pandemie hat vielen Menschen die Augen für Innovationen in einer Weise geöffnet, die vorher nicht möglich war.“ <a href="https://www.bayer.com/de/news-stories/zell-und-gentherapie">Da widerspricht ihm die Bayer-Firmenhomepage</a>: „Bei der Gentherapie kommt genetisches Material als Arzneimittel zum Einsatz, um so die Funktionsstörung des Gens zu korrigieren oder das veränderte Gen, das die Erkrankung verursacht, zu ersetzen.“ Das ist bei der mRNA-Impfung nicht der Fall.
          </p><p>Ich kann nur Mutmaßungen anstellen, warum er das gesagt hat. Vielleicht kennt er sich nicht aus, vor allem aber verdient Bayer kein Geld mit mRNA-Impfstoffen. Sie haben eine Partnerschaft mit CureVac, der Impfstoff ist aber gefloppt.  Bayer verdient Geld mit Gentherapien. Bayer „konzentriert sich [...] auf ausgewählte Bereiche der Zell- und Gentherapien, darunter Stammzelltherapien (mit Schwerpunkt auf induzierte pluripotente Zellen oder iPSCs), Gen-Augmentation, Gen-Editing und allogene Zelltherapien in verschiedenen Indikationen” <a href="https://media.bayer.de/baynews/baynews.nsf/id/Bayer-will-mit-neuer-Zell-und-Gentherapie-Plattform-Innovationen-im-Pharmabereich-beschleunigen">und will da auch weiter wachsen</a>. Es liegt nahe, dass Oelrich die Popularität und die relativ breite Akzeptanz der COVID-Impfung nutzen will, um Patient*innen die Gentherapie schmackhaft zu machen.
          </p><p>Als nächstes wird Peter Doshi zitiert, ein Pharmazieprofessor. Ich habe das Zitat nicht geprüft, es wird schon stimmen, ich kenne Doshi auch nicht. Seine erste Aussage ist trivial: mRNA-Impfstoffe unterscheiden sich qualitativ von “Standardimpfstoffen”. Klar, wenn man “Standardimpfstoffe” als Totimpfstoffe, abgeschwächte Erreger, oder Proteine, die keine mRNA sind, definiert, dann ist ein mRNA-Impfstoff was anderes. Der Knackpunkt ist, dass es technischen Fortschritt gibt. mRNA in Zellen einschleußen konnte man das erste mal 1989, dann musste man daran natürlich recht lange forschen, damit man die Technik sicher einsetzen kann und 2018 kam das erste mRNA-Medikament auf den Markt.
          </p><p>Ich finde, dass das auch erklärt, warum Marriam-Webster früher mRNA nicht als Impfstoff-Technik aufgelistet hat. Es gab früher keine mRNA-Impfstoffe. Jetzt gibt es welche, das Wörterbuch passt sich an. Klar, dass hört sich erstmal gruselig an, dass das so neu scheint. Die Mechanik von mRNA ist aber sehr gut und sehr lange erforscht. Die Funktionsweise ist recht simpel und deterministisch (Ribosomen bauen exakt nach mRNA-Bauplan Proteine).
            </p><p>Dann fragt Doshi, was passiere, wenn wir Impfstoffe als Medikamente bezeichnen. Impfstoffe sind Medikamente, ich weiß nicht, worauf er hinauswill. Und natürlich ist das ein Impfstoff. Das Immunsystem wird durch die Impfung auf eine Exposition mit dem Erreger vorbereitet. Doshi hat natürlich recht, dass jedes Produkt kritisch betrachtet werden muss. Eine individuelle Abwägung pro Medikament ist wichtig. Ich bin der Meinung, das ist geschehen. Auch finde ich, dass eine Impfpflicht immer sorgfältig abgewogen werden muss. Aber auch das passiert. Den Impfstoff gibt es seit einem Jahr. Lange wurde eine Impfpflicht komplett ausgeschlossen. Auch jetzt wird das Thema selbst im „Mainstream“ kontrovers diskutiert.
          </p><p>Anschließend kommen im Text ein paar Absätze über Verordnungen und weitere juristische Texte, die verändert wurden. Diese Veränderungen sind Teil eines komplett normalem Prozess. Die Tausenden Bundesbeamten sind nicht nur für Däumchendrehen eingestellt. Hier ist klar, dass eine Verordnung an den technischen Fortschritt angepasst wurde, im Jahr 2009, als die Verwendbarkeit von mRNA auch für Impfstoffe langsam klar wurde. Das war 9 Jahre vor Zulassung des ersten mRNA-Medikaments. “Ohne diese politisch bestimmte Definitionsänderung würden die mRNA-Präparate, deren verpflichtende Anwendung aktuell geplant wird, rechtlich nicht als Impfungen [,] sondern als Gentherapeutika gelten”. Das kann sein. Letztendlich ändert die juristische Bezeichnung in einer Verordnung nichts an der Sicherheit oder dem Einsatzzweck eines Wirkstoffes.
          </p><p>Dann kommt ein Absatz darüber, dass die Pharmakonzerne Geld mit neuartigen Medikamenten verdienen will. Das stimmt. Das war aber in der Marktwirtschaft schon immer so. Pharmakonzerne wollen Geld verdienen, indem „‚bei verbesserter Wirksamkeit und geringeren Nebenwirkungen […] der Preis des Medikaments eine eher untergeordnete Rolle’“ spielt. Natürlich ist für die Pharmakonzerne der Profit die Motivation, bessere Medikamente herzustellen, und nicht Altruismus. Das kann man kritisieren, es ändert aber nichts an „verbesserter Wirksamkeit und geringeren Nebenwirkungen”.
          </p><p>Stichwort “Geld”: Der Text zitiert auch aus einem Geschäftsbericht zum Börsengang. Der Bericht sagt, dass ihre Medikamente vielleicht nicht zugelassen werden, was ein Geschäftsrisiko für ein Pharmaunternehmen ist. Aber die Impfung ist zugelassen und der Geschäftsbericht bezieht sich hier vor allem auf andere Produkte, die Biontech plant. Ich finde weder „BNT162“, noch „Comirnaty“, noch „COVID“ und auch nicht „Corona“ im Text. Das Zitat verfehlt das Thema.
          </p><p>Der Text schließt mit: “Die Annahme, mRNA-Präparate, wie das von Biontech, seien Impfstoffe, basiert auf einer politischen Definitionsänderung, die ohne parlamentarische Debatte und versteckt in einem 72-seitigen Änderungsantrag vor 12 Jahren beschlossen wurde”. Ich finde nicht, dass der Text dies hinreichend herleitet. Ein Impfstoff hätte ich als das definiert, was den Körper auf eine Exposition mit dem Erreger vorbereitet. Für mich ist es echt unerheblich, wie das irgendeine juristische Verordnung definiert. Und auch wenn der Text das Behauptet und ein Bayer-Manager, es ist nach allem, was ich gelesen habe, keine Gentherapie. Wer sucht, der findet, und kann sich auch auf abenteuerliche Weise herleiten, dass das gar keine Impfung ist.
          </p><p>Es ist momentan eine persönliche Frage, ob man sich gegen COVID impfen lassen will. Es ist eine Abwägung der Risiken der Impfung und dem Risiko, COVID ohne Impfung zu bekommen (wobei hier echt mehr schief gehen kann). Es ist eine politische Abwägung, ob die aktuelle Extremsituation es zulässt, dass man mit einem Bußgeld belegt wird, wenn man sich nicht impfen lässt. Das alles hat nichts damit zu tun, wie man die ganze Kiste nennt. Die Impfung ist sicher. Sie schützt bei den neuen Varianten nicht mehr so gut vor Ansteckung und Symptomen . Sie ist vielleicht noch nicht das Beste, was es gibt. Sie ist vielleicht nicht die letzte, die wir bekommen. Aber selbst bei Omikron soll sie vor den schwersten Verläufen schützen, das hat irgendwas mit „T-Zell-Immunität“ zu tun. Ich kann nur raten, sich impfen zu lassen, selbst wenn man es „Gentherapie“ nennt.
</p>
      </c-v-block>
      </article>
    
  </div>
</template>

<script>

import CVBlock from "@/components/CVBlock";

export default {
  name: "juri-blog",
  components: {CVBlock}
}
</script>

<style scoped lang="scss">
h3 {
  padding: 30px 0 10px;
}

article {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

p {
  text-align: start;
}
</style>