<template>
    <div class="err404">
        <h2></h2>
    </div>
</template>

<script>
    export default {
        name: "err404"
    }
</script>

<style scoped>
    .err404{
        margin: 50px 0;
    }

    button{
        background: lightgrey;
        border: none;
        padding: 10px 20px;
    }

</style>