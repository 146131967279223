import Vue from 'vue'
import VueRouter from 'vue-router'
//import App from "../App.vue"
// import life from "@/components/life"
import completeTitle from "@/components/completeTitle";
import study from "@/components/study";
import imprint from "@/components/imprint";
import work from "@/components/work"
import err404 from "@/components/err404";
import skills from "@/components/skills";
import juriBlog from "@/components/juriBlog"


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 0,
    components: {top: completeTitle, bottom: ""}
  },
  {
    path: '/skills',
    name: 1,
    components: {top: "", bottom: skills}
  },
  {
    path: '/study',
    name: 2,
    components: {top: "", bottom: study}
  },
  {
    path: '/work',
    name: 3,
    components: {top: "", bottom: work}
  },
  {
    path: '/blog',
    name: 4,
    components: {top: "", bottom: juriBlog}
  },
  {
    path: '/imprint',
    name: 'imprint',
    components: {top: "", bottom: imprint}
  },
  {
    path: '/*',
    name: '404',
    components: {top: "", bottom: err404}
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
