<template>
  <div class="skills">
    <h2>Skills</h2>
    <article>
      <c-v-block>
<!--        <template v-slot:block-date>18th August 1996</template>-->
        <template v-slot:block-title>Natural Languages</template>
        <div>German (native)</div>
        <div>English (C1, fluent)</div>
        <div>Spanish, Slovene, French (basics)</div>
      </c-v-block>

      <c-v-block>
<!--        <template v-slot:block-date>2002 - 2014</template>-->
        <template v-slot:block-title>Computer Languages</template>
        <div>Javascript</div>
        <div>Typescript</div>
        <div>Vue.js</div>
        <div>Java</div>
        <div>Python</div>
        <div>HTML</div>
        <div>CSS</div>
        <div>SQL</div>
        <div>Prolog</div>
        <div>Haskell</div>
        <div>C</div>
        <div>C#</div>
      </c-v-block>

      <c-v-block>
        <template v-slot:block-date>Before the pandemic</template>
        <template v-slot:block-title>Hobbies and Activities</template>
      </c-v-block>

      <c-v-block>
        <template v-slot:block-date>Summer 2019</template>
        <template v-slot:block-title>Travel through Viet Nam</template>
      </c-v-block>
    </article>
  </div>
</template>

<script>
import CVBlock from "@/components/CVBlock";
export default {
  name: "skills",
  components: {CVBlock}
}
</script>

<style scoped>
h3 {
  padding: 30px 0 10px;
}

article {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
</style>