<template>
    <div class="work">
        <h2>Work</h2>
        <article>

            <c-v-block>
                <template v-slot:block-date>October 2019 - August 2020</template>
                <template v-slot:block-title>Student software developer at <a
                        href="https://www.arxes-tolina.de/tolina.nsf/id/Startseite_DE">arxes-tolina</a>, Berlin
                </template>
                <div>Working on a large project in the health insurance sector</div>
                <div>Full-stack (Docker, Java, postgreSQL, jOOQ, Typescript, Vue, SCSS, ElementUI)</div>
                <div>Scrum/Kanban with JIRA</div>
            </c-v-block>

        </article>
        <article>
            <c-v-block>
                <template v-slot:block-date>April 2017 – September 2018</template>
                <template v-slot:block-title>Tutor at TU Berlin</template>
                <div>Two Modules, 60 students each</div>
                <div><a href="https://www.dima.tu-berlin.de/menue/teaching/bachelor/">Information Systems & Data
                    Analysis</a> (SQL, noSQL, database design, database normalization, easy ML
                    algorithms)
                </div>
                <div>
                    <a href="https://www.snet.tu-berlin.de/menue/teaching_and_exams/winter_term_2019_2020/webtechnologien_wt2019_2020/parameter/en/">Webtechnologien</a>
                    (JavaScript, NodeJS, HTML5, CSS3, web communication)
                </div>
            </c-v-block>

            <c-v-block>
                <template v-slot:block-date>April 2016 – March 2017</template>
                <template v-slot:block-title>Office assistant at Tax Accountancy Bakalović & Mahling, Berlin</template>
                <div>Office assistant (copying, sorting, account assigning)</div>
            </c-v-block>

            <c-v-block>
                <template v-slot:block-date>July 2013 – March 2016</template>
                <template v-slot:block-title>Holiday worker at <a href="https://sembach.com/">Sembach Technical
                    Ceramics</a>, Lauf/Pegnitz
                </template>
                <div>first machine operation, later own project, reorganizing spare parts inventory and procurement process</div>
            </c-v-block>
        </article>
    </div>
</template>

<script>
    import CVBlock from "@/components/CVBlock";

    export default {
        name: "work",
        components: {CVBlock}
    }
</script>

<style scoped>
    h3 {
        padding: 30px 0 10px;
    }

    article {
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
    }
</style>