<template>
    <div id="subtitle">
      <div id="actual-subtitle">
        MASTER STUDENT COMPUTER SCIENCE
      </div>
      <div id="visit-me">
        (visit me on <a href="https://www.linkedin.com/in/juri-otto-orson-welz-753b4a138">LinkedIn</a>)
      </div>
    </div>
</template>

<script>
    export default {
        name: "subtitle"
    }
</script>

<style lang="scss" scoped>
    @import "../assets/master";

    #subtitle {
      margin: 10vh auto;
      overflow-wrap: anywhere;
    }

    #actual-subtitle {
        font-size: $SUBTITLE_SIZE;

    }

    #visit-me {
      font-size: $SUBTITLE_SIZE / 2;
    }

    @media screen and (max-width: $S_MAX) {
        #actual-subtitle {
            font-size: $SUBTITLE_SIZE_S;
        }

      #visit-me {
        font-size: $SUBTITLE_SIZE_S / 2;
      }
    }

    @media screen and (min-width: $S_MIN) and (max-width: $M_MAX){
        #actual-subtitle {
            font-size: $SUBTITLE_SIZE_S;
        }

      #visit-me {
        font-size: $SUBTITLE_SIZE_S / 2;
      }
    }

</style>