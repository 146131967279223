<template>
    <div class="completeTitle">
        <juriwelz></juriwelz>
        <subtitle></subtitle>
    </div>
</template>

<script>
    import juriwelz from "@/components/juriwelz";
    import subtitle from "@/components/subtitle";

    export default {
        name: "completeTitle",
        components: {
            juriwelz,
            subtitle
        }

    }
</script>

<style scoped>

</style>