<template>
  <div class="study">
    <h2>Study</h2>
    <article>
      <h3>University of Ljubljana</h3>
      <c-v-block>
        <template v-slot:block-date>winter semester 2020/2021</template>
        <template v-slot:block-title>Erasmus Semester</template>
        <div>Bioinformatics, Computer Based Sound Production, Information Systems Security and Privacy, Slovene Language</div>
        <div>I wrote this <a href="https://pitch-recognition.juriw.com">voice pitch classification program</a>. The autocorrelation approach works quite well.</div>
        <div>Despite COVID restrictions an enriching experience, although I would have loved it to see the university building more than two times.</div>
      </c-v-block>
    </article>
    <article>
      <h3>Technische Universität Berlin</h3>

      <c-v-block>
        <template v-slot:block-date>
          since winter semester 2019/2020
        </template>
        <template v-slot:block-title>
          MSc Computer Science
        </template>
        <div>Scholarship by the Studienstiftung</div>
        <div>Focus on Machine Learning and Privacy Engineering. For example, I passed Prof. Klaus-Robert Müller's
          Machine Learning I and II and Dr. Frank Pallas' Privacy Engineering
        </div>
        <div>Current grade ca. 1,2</div>
        <div>Scholarship by the <a href="https://www.studienstiftung.de/">Studienstiftung (German Academic
          Scholarship Foundation)</a></div>
      </c-v-block>

      <c-v-block>
        <template v-slot:block-date>
          winter semester 2015/2016 - winter semester 2018/2019
        </template>
        <template v-slot:block-title>
          BSc Informatik
        </template>
        <div>Bachelor thesis on "Using Web Protocols to Communicate Privacy Preferences", advised by Max.-R.
          Ulbricht and supervised by Prof. Stefan Tai (<a
              href="https://www.ise.tu-berlin.de/menue/information_systems_engineering/parameter/en/">ISE</a>) and Prof.
          Axel Küpper (<a
              href="https://www.snet.tu-berlin.de/">SNET</a>)
        </div>
        <div>Focus on topics of Artificial Intelligence and Information Governance, Privacy</div>
        <div>Scholarship by the <a href="https://www.studienstiftung.de/">Studienstiftung (German Academic
          Scholarship Foundation)</a> since 2018
        </div>
        <div>Final Grade 1,4</div>
      </c-v-block>
    </article>
  </div>
</template>

<script>

import CVBlock from "@/components/CVBlock";

export default {
  name: "study",
  components: {CVBlock}
}
</script>

<style scoped lang="scss">
h3 {
  padding: 30px 0 10px;
}

article {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
</style>